import { navigate } from 'gatsby';
import * as React from 'react';
import Typewriter from 'typewriter-effect';

import Layout from '~/components/layout';
import Seo from '~/components/seo';

import * as Styled from '../styling/styles';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Styled.Container>
      <p>
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .pauseFor(2500)
              .typeString('Software engineering is ')
              .pauseFor(1000)
              .typeString('<span style="color: red">hard</span>')
              .pauseFor(100)
              .deleteChars(4)
              .pauseFor(100)
              .typeString('<span style="color: #7A428D">fun</span>')
              .pauseFor(100)
              .deleteChars(3)
              .pauseFor(100)
              .typeString('<span style="color: #dd7732">a journey</span>')
              .deleteChars(9)
              .typeString('<span style="color: #00aa00;">life</span>')
              .pauseFor(1000)
              .deleteAll()
              .typeString('chukanwobi.com')
              .callFunction(() => navigate('/about'))
              .start();
          }}
        />
      </p>
    </Styled.Container>
  </Layout>
);
export default IndexPage;
