import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;

  p {
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    text-align: center;
    overflow: hidden;
    padding: 0.5rem;
  }
  .typed {
    font-weight: normal;
    color: ${({ theme }) => theme.accent};
  }
`;

const blink = keyframes`
0% {background-color: #ccc}
49% {background-color: #ccc}
50% {background-color: transparent}
99% {background-color: transparent}
100% {background-color: #ccc}
`;

export const TypedText = styled.span`
  font-weight: normal;
  color: ${({ theme }) => theme.accent};
`;

export const Cursor = styled.span<{
  typing: boolean;
}>`
  display: inline-block;
  width: 3px;
  background: #ccc;
  margin-left: 0.1rem;
  animation: ${({ typing }) =>
    typing
      ? `none;`
      : css`
          ${blink} 1s infinite;
        `};
`;

export const About = styled.div`
  font-family: 'Space Mono', monospace;
  padding-top: 1rem;
  width: 80%;
  margin: auto;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  .pwd {
    font-size: 1rem;
    letter-spacing: -1px;
    color: #00aa00;
  }
`;

export const Nav = styled.nav`
  width: 100%;
  ul {
    display: flex;
    list-style: none;
    gap: 20px;
    align-items: center;
    color: ${({ theme }) => theme.accent};
    li {
      cursor: pointer;
    }
  }
`;

export const H1 = styled.h1`
  color: ${({ theme }) => theme.accent};
  font-size: 1rem;
  letter-spacing: -1px;
  span {
    color: #00aa00;
  }
`;

export const H2 = styled.h2`
  color: ${({ theme }) => theme.accent};
`;

export const H3 = styled.h3`
  color: ${({ theme }) => theme.accent};
`;

export const OnlineLink = styled.ul`
  display: flex;
  width: 100%;
  gap: 20px;
  li {
    a {
      color: ${({ theme }) => theme.accent};
      text-decoration: none;
      &:hover {
        color: #00aa00;
      }
    }
  }
`;
